import LazyLoad from "vanilla-lazyload";
import Splide from '@splidejs/splide';
import MicroModal from 'micromodal';

document.onreadystatechange = function() {
	switch (document.readyState) {
		case 'loading':
			// The document is still loading.

			break;
		case 'interactive':
			// The document has finished loading. We can now access the DOM elements.
			// But sub-resources such as images, stylesheets and frames are still loading.
			// Equivalent of document.ready

			// Run lazy load on all elements with the class of 'lazy'
			var siteLazyLoad = new LazyLoad({
				elements_selector: ".lazy"
			});

			const hamburger = document.querySelector('.js-hamburger');
			const primenav = document.querySelector('.nav__wrapper');
			const body = document.querySelector('body');
			if(hamburger !== null) {
				hamburger.addEventListener('click', function() {
					hamburger.classList.toggle('is-active');
					primenav.classList.toggle('nav__wrapper--active');
					body.classList.toggle('nav-active');
				});
			}

			MicroModal.init();
			const modals = document.querySelectorAll('.js-terms-modal');
			if (modals) {
				Array.from(modals, modal => modal.addEventListener('click', e => e.preventDefault()));
			}

			const subNavTriggers = document.querySelectorAll('.js-subnav-open, .js-subnav-close, .js-hamburger');
			Array.from(subNavTriggers, (trigger) => {
				trigger.addEventListener('click', () => {
					const nodeId = trigger.dataset.subnav;
					if (!nodeId) {
						const subNavs = document.querySelectorAll(`.js-subnav`);
						Array.from(subNavs, (subNav) => subNav.classList.remove('nav__subnav-container--mobile-active'));
					} else {
						document.querySelector(`.js-subnav[data-subnav="${nodeId}"]`).classList.toggle('nav__subnav-container--mobile-active');
					}
				})
			});

			break;
		case 'complete':
			// The page is fully loaded.
			// Equivalent of window.load

			// Init header search
			$app.headerSearch();

			// Init sticky banner
			$app.promoBanners();

			// Inline all svg img tags
			$app.inlineSvg('.isvg');

			$app.resetRetailerSearchForm();

			$app.scrollToRetailerSearch();

			if (document.querySelectorAll('.downloads__slider').length) {
				const sliders = document.querySelectorAll('.downloads__slider');
				Array.from(sliders, function(slider) {
					$app.createDownloadSlider(slider);
				});
			}

			if (document.querySelectorAll('.range-gallery__slider').length) {
				const sliders = document.querySelectorAll('.range-gallery__slider');
				Array.from(sliders, function(slider) {
					$app.createRangeSlider(slider);
				});
			}

			break;
	}
};

// Resize event
window.addEventListener('resize', function(event){
	// Example
	// if ($app.wW() >= $app.breakpoints().small) {}

	$app.promoBanners();
});

// Utilities and behaviours namespaced to $app
// use these functions by calling $app.method();
var $app = {
	// Allows you to query the window width against the breakpoints. These MUST match breakpoints
	// set in _vars.scss

	// Example
	//
	// if ($app.wW() >= $app.breakpoints().small) {
	//  code to run when the window hits large breakpoint
	// }
	//

	createDownloadSlider: function(element) {
		new Splide(element, {
			updateOnMove: true,
			perPage: 2,
			arrows: false,
			type   : 'loop',
			gap: '3rem',
			padding: '20.5%',
			speed: 500,
			breakpoints: {
				768: {
					perPage: 1,
				},
				460: {
					perPage: 1,
					gap: '2rem',
					padding: '15%',
				},
			}
		}).mount();
	},

	createRangeSlider: function(element) {
		new Splide(element, {
			updateOnMove: true,
			arrows: false,
			type   : 'loop',
			gap: '2rem',
			speed: 500,
			breakpoints: {
				640: {
					padding: '0',
				},
			}
		}).mount();
	},

	breakpoints: function() {
		obj = {
			'xsmall':	'460',
			'small': 	'640',
			'medium':	'768',
			'large':	'1024',
			'xlarge':	'1280',
			'xxlarge':	'1600'
		}

		return obj;
	},

	wW: function(){
		return window.innerWidth;
	},

	// Sets element heights to the same
	// Usually worth duplicating code in window.load and window.resize
	// Will automatically reset the height correctly on resize thanks to the line
	// .css({'height': 'auto'});
	// I usually try to use it with the class .js-height

	// Usage: $app.equalHeights('.js-height');

	// If you're using the same 'module' in multiple areas this will prevent it from
	// setting the height across all of them
	// var sameHeight = document.querySelectorAll(".js-sameheight");
	// sameHeight.forEach((e) => {
		// var children = e.querySelectorAll('.js-height');
		// $app.equalHeights(children)
	// });
	// });

	// $('.js-sameheight').each(function(){
	// 	var children = $(this).find('.js-height');
	//
	// 	$app.equalHeights(children);
	// })
	equalHeights: function(el) {
		var findElement = $(el);
		var tallest = 0;

		findElement.css({'height': 'auto'});

		// Loop over matching divs
		for(var i = 0; i < findElement.length; i++)
		{
			var ele = findElement[i];
			var eleHeight = ele.offsetHeight;
			tallest = (eleHeight>tallest ? eleHeight : tallest); /* look up ternary operator if you dont know what this is */
		}
		for(i = 0; i < findElement.length; i++)
		{
			findElement[i].style.height = tallest + 'px';
		}
	},


	// function for setting cookies
	setCookie: function(name,value,days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	},

	// function for getting cookies
	getCookie: function(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	},

	// function to erase a cookie
	eraseCookie: function(name) {
		document.cookie = name+'=; Max-Age=-99999999;';
	},

	// function to inline an img with an svg
	// Usage: $app.inlineSvg('.isvg');
	inlineSvg: function(selector) {

		document.querySelectorAll(selector).forEach(el => {
			const imgID = el.getAttribute('id');
			const imgClass = el.getAttribute('class');
			const imgURL = el.getAttribute('src');

			fetch(imgURL).then(data => data.text()).then(response => {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(response, 'text/html');
				let svg = xmlDoc.querySelector('svg');

				if (typeof imgID !== 'undefined') {
					svg.setAttribute('id', imgID);
				}

				if (typeof imgClass !== 'undefined') {
					svg.setAttribute('class', imgClass + ' replaced-svg');
				}

				svg.removeAttribute('xmlns:a');

				el.parentNode.replaceChild(svg, el);
			});
		});
	},

	// shows the search form input when the icon is clicked the first time
	// if the form input is visible then the button submits the form
	headerSearch: function() {
		const headerSearchButton = document.querySelector('.js-search-button')
		const searchForm = headerSearchButton.parentElement;
		headerSearchButton.addEventListener('click',function(e){
			if(searchForm.classList.contains('search-form--hidden')){
				e.preventDefault();
				searchForm.classList.remove('search-form--hidden')
				// apply focus to the form input
				searchForm.querySelector('[name=q]').focus();
			}
		})
	},

	promoBanners: function() {
		const stickyBanner = document.querySelectorAll('.js-banner-sticky');
		Array.from(stickyBanner, (banner) => {
			const bannerId = banner.dataset.banner;
			const bannerHeight = banner.offsetHeight;
			const placeholder = document.querySelector('[data-placeholder="' + bannerId + '"]');
			placeholder.style.height = bannerHeight + 'px';
		})
	},

	resetRetailerSearchForm: function() {
		const form = document.getElementById('retailer-search-form');

		if (form != undefined) {
			let resetBtn = form.querySelector('[type="reset"]');
			let url = window.location.href;
			let resetUrl = url.split('?')[0];

			if (resetUrl != undefined) {
				resetBtn.addEventListener('click', (e) => {
					window.location.href = resetUrl;
				});
			}
		}
	},

	scrollToRetailerSearch: function() {
		const section = document.getElementById('search-results');

		if (section != undefined) {
			let query = new URLSearchParams(location.search);

			if (query.has('near')) {
				section.scrollIntoView({ behavior: 'smooth', inline: 'start' });
			}
		}
	}

} // end of $app
